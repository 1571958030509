import React from 'react';

import JobScreenShot from '../../assets/img/screenshots/mobile/jobs.png';
import IphoneDevice from '../../assets/img/devices/iphonex.svg';
import ProfileScreenShot from '../../assets/img/screenshots/mobile/profile.png';
import MobileIllustration from '../../assets/img/illustrations/mobile.svg';
import ResumeIllustration from '../../assets/img/illustrations/Resume.svg';
import AppStoreButton from '../../assets/img/buttons/button-app.png';
import PlayStoreButton from '../../assets/img/buttons/button-play.png';

const Welcome: React.FC = () => {
  return (
    <>
      <div className="container mt-11">
        <div className="row align-items-center text-center text-md-start">
          <div className="col-12 col-md-6">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              src={MobileIllustration}
              className="img-fluid mw-130 float-end mb-6 mb-md-0"
            />
          </div>
          <div className="col-12 col-md-6">
            <h1 className="display-1 fw-bold">
              <div>Recrutalentos.</div>
            </h1>

            <p className="fs-lg text-muted mb-0">
              Recrutalentos é um app do time
              <span className="ml-2  mr-2 text-primary">
                Automação Curitiba
              </span>
              desenvolvido para ajudar os futuros IDprozeiros a se candidatarem
              a novas vagas.
            </p>
          </div>
        </div>
      </div>

      <section className="pt-6 pt-md-8 mt-5">
        <div className="container">
          <div className="row align-items-center justify-content-center justify-content-md-between">
            <div className="col-10 col-sm-8 col-md-6 order-md-2">
              <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                <div className="device device-iphonex">
                  <img
                    src={JobScreenShot}
                    className="device-screen"
                    alt="..."
                  />
                  <img src={IphoneDevice} className="img-fluid" alt="..." />
                </div>

                <div className="device device-iphonex">
                  <img
                    src={ProfileScreenShot}
                    className="device-screen"
                    alt="..."
                  />
                  <img src={IphoneDevice} className="img-fluid" alt="..." />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <h1 className="fw-bold">
                Fique por
                <span className="ml-2 text-primary">dentro</span>
                <br />
                de vagas disponíveis
              </h1>

              <p className="fs-lg text-muted mb-6">
                Dentro do aplicativo você vai poder todas as vagas disponíveis e
                vai ser avisado quando tiver um nova
              </p>

              <form className="mb-6 mb-md-8 mb-md-0 order-md-1">
                <div className="row gx-4">
                  <div className="col" />
                  <div className="col-auto" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-12">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <img
                src={ResumeIllustration}
                alt="..."
                className="img-fluid mw-md-130"
              />
            </div>
            <div className="col-12 col-md-6">
              <span className="badge badge-pill badge-primary-soft mb-3">
                <span className="h6 text-uppercase">Mensagens</span>
              </span>

              <h2>
                Receba mensgaens da
                <br />
                <span className="text-primary">Automação Curitiba</span>
              </h2>

              <p className="font-size-lg text-gray-700 mb-6">
                Receba mensagens diretamente do app, e seja notifcado toda vez
                que receber uma novidade
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-6 pt-md-8">
        <div className="container pb-6 pb-md-8 border-bottom">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h3 className="font-weight-bold mb-1">Baixe agora!</h3>

              <p className="text-muted mb-6 mb-md-0">Gerencie com estilo!</p>
            </div>

            <div className="col-auto">
              <a href="#!" className="text-reset d-inline-block mr-1">
                <img
                  src={AppStoreButton}
                  className="img-fluid"
                  alt="..."
                  style={{ maxWidth: 155 }}
                />
              </a>

              <a href="#!" className="text-reset d-inline-block m-lg-4">
                <img
                  src={PlayStoreButton}
                  className="img-fluid"
                  alt="..."
                  style={{ maxWidth: 155 }}
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
