import React, { useCallback, useEffect, useRef } from 'react';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';

import Password from '../../assets/img/illustrations/password.svg';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const token = location.search.replace('?token=', '');

    if (!token) {
      history.push('/');
    }
  }, [history, location]);

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('Senha obrigatória'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password')],
            'Confirmação incorreta',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { password } = data;

        const token = location.search.replace('?token=', '');

        if (!token) {
          history.push('/');
        }

        await api.post('/reset', {
          password,
          token,
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [history, location],
  );

  return (
    <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
          <div className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
            <img src={Password} alt="..." className="img-fluid" />
          </div>
          <div className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">
            <h1 className="mb-0 fw-bold text-center">Redefinir senha</h1>

            <p className="mb-6 text-center text-muted">
              Coloque sua nova senha
            </p>

            <Form ref={formRef} onSubmit={handleSubmit} className="mb-6">
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Nova Senha
                </label>
                <Input
                  name="password"
                  type="password"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Repetir Senha
                </label>
                <input
                  name="password_confirmation"
                  type="password"
                  className="form-control"
                />
              </div>

              <button className="btn w-100 btn-primary" type="submit">
                Recuperar
              </button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
